import * as React from "react"

function BeardedMan(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 351.84" {...props}>
      <defs>
        <style>{".prefix__cls-2{fill:#4e3726}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            d="M18 208.72S28.8 292 90.09 320.49c14.41 6.7 24.74 8.17 45.49 0C203 293.91 215 207.72 215 207.72l5.86-4.9A42.21 42.21 0 00233 173.59v-22.17c0-4.81-4.4-8.7-9.83-8.7H216v-29.39c0-48.94-44.77-88.62-100-88.62S16 64.39 16 113.33l1 29.39H9.27a9 9 0 00-9.27 8.7v22.17a43.48 43.48 0 0011.46 29.23l5.54 4.9"
            fill="#f0b289"
          />
          <path
            className="prefix__cls-2"
            d="M100.19 137.61a10.29 10.29 0 01-6.19 8.92c-5.25 2.32-35.26-5.29-43.68-3.51s-18.25 8.39-18.25 8.39 12.64-15.9 20.62-16.57 47.5 2.77 47.5 2.77zM132.39 138.63a10.29 10.29 0 006.42 8.74c5.32 2.16 35.08-6.35 43.55-4.82s18.5 7.83 18.5 7.83-13.12-15.51-21.11-15.94-47.36 4.19-47.36 4.19z"
          />
          <ellipse cx={115} cy={258.72} rx={33} ry={8} fill="#fff" />
          <path
            className="prefix__cls-2"
            d="M213.74 177.15l-.73-1.67s-7.5 30.88-12.35 45.41c-2.35 7-17.76 20.38-31 38.87a39.6 39.6 0 00-2.09 3.78 44.8 44.8 0 00-2.27-12.69c-4-12.23-33.3-13-49.05-13-15.22 0-45 .8-48.92 13a44.46 44.46 0 00-2.25 12.46 38.1 38.1 0 00-2-3.55c-13.24-18.49-28.65-31.83-31-38.87C27.26 206.36 20 175.72 20 175.72l-1 1.43s-4.69 26.61 1.81 52.7c3.17 12.68 26.05 66.91 35.74 78.88l2 2.4a6.47 6.47 0 00.51.43 16.31 16.31 0 016.19 9.53 11.69 11.69 0 001.23 3.74c4.65 7.31 26 27 49.87 27s45.19-19.71 49.85-27a12.36 12.36 0 002-3.65 23.55 23.55 0 014.12-7.93 21.38 21.38 0 011.95-2.11c.67-.8 1.32-1.6 2-2.4 9.69-12 32.57-66.2 35.74-78.88 6.42-26.1 1.73-52.71 1.73-52.71zm-71.15 122.16c-16.89 0-15.91-15.5-15.91-15.5 1.33-1.33 8.65.67 8.65.67l.25-2.87c0-2.44-11.55-3.55-19.24-3.55s-19.29 1.11-19.29 3.55l.22 2.88s7.31-2 8.64-.66c0 0 1 15.51-15.92 15.51s-19.4-2-19.4-19.48a30.14 30.14 0 01.54-5.66c2.15-5.91 7.45-13.49 20.5-13.49 19.16 0 23.85-6.71 24.62-8.11 1.31 1.4 5.55 8.11 24.71 8.11 12.93 0 18.29 7.43 20.51 13.31a30.57 30.57 0 01.57 5.84c-.04 17.49-2.56 19.45-19.45 19.45z"
          />
          <path
            className="prefix__cls-2"
            d="M11 93.72l9 82S21 87 37.11 75.58s31.27 5.87 75.82 5.87 42.67-13.09 73.53-5.21S213 175.72 213 175.72 230.44 79.52 227.16 71c-1.29-3.33-4.48-5-7.9-5.71 5.74-1.56 7.74-.56 7.74-.56-9-4-12.84-2.45-17.6-.51.93-10.61 2.6-36.49-21.4-51.49a22 22 0 017 13c-8-16-21.09-23.59-81.94-25.63C15.37-3.19 11 84.72 11 84.72z"
          />
        </g>
      </g>
    </svg>
  )
}

export default BeardedMan
